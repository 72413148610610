import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as RoomProps } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const Filters = memo(function Filters({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined} dial={5} row wrap>
      {uniqBy(rooms, 'title').map((item, index) => {
        if (!item.title) {
          return undefined
        }

        const section = item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-159}
            spy={true}
            smooth={true}
            to={`room-${section}`}
          >
            {item.title}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 2.625rem 1.875rem;
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 3;
  &.fixed {
    animation: filtersFadeIn 0.3s;
    box-shadow: inset ${rgba(theme.colors.variants.neutralDark1, 0.06)} 0px 6px
      12px 0;
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
    position: fixed;
    top: 5.625rem;
  }

  @keyframes filtersFadeIn {
    0% {
      top: 0;
    }
    100% {
      top: 5.625rem;
    }
  }

  @media (max-width: 1199px) {
    &.fixed {
      top: 3.75rem;
    }

    @keyframes filtersFadeIn {
      0% {
        top: 0;
      }
      100% {
        top: 3.75rem;
      }
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Filter = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  margin: 0 2.625rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  &.active,
  &:hover {
    &:after {
      right: 0;
    }
  }
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
    &:before {
      display: none;
    }
  }
  &:before,
  &:after {
    content: '';
    background: ${({ theme }) => theme.colors.variants.neutralDark5};
    position: absolute;
  }
  &:before {
    width: 0.125rem;
    height: 100%;
    opacity: 0.1;
    top: 0;
    right: -2.625rem;
  }
  &:after {
    height: 0.125rem;
    bottom: -0.25rem;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }
`
