import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { Gallery } from 'app/components/GalleryCategories/Gallery'
import { Maximize } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import KeyboardControls from 'app/utils/SliderKeyboardControls'
import useLockedBody from 'app/utils/useLockedBody'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  images: ImageProps[]
  languageCode: string
  thumbnails?: ImageProps[]
}

export const Slider = memo(function Slider({
  images,
  languageCode,
  thumbnails,
}: Props) {
  if (!images) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      defaultAnimation: {
        duration: 1200,
      },
      drag: false,
      loop: true,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel)
        setClickedSlide(s.track.details.rel)
      },
      detailsChanged(s) {
        images.length > 1 ? setDetails(s.track.details) : null
      },
      breakpoints: {
        '(max-width: 1023px)': {
          drag: true,
        },
      },
    },
    [KeyboardControls],
  )

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(currentSlide)
            break
        }
      })
    }
  }, [currentSlide, modalGalleryStatus, instanceRef, sliderRef])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media className="zoom-icon" greaterThanOrEqual="ipadHorizontal">
          <Maximize />
        </Media>

        <Carousel
          className="slider"
          ref={sliderRef}
          onClick={() => {
            if (!modalGalleryStatus) {
              setLocked(true)
              setModalGalleryStatus(true)
            } else {
              setLocked(false)
              setModalGalleryStatus(false)
            }

            setTimeout(() => {
              setClickedSlide(null)
            }, 100)
          }}
        >
          {uniq(thumbnails).map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Inner style={positionStyle(index)}>
                <Image
                  animationStart={
                    loaded[index] || nextLoaded[index] ? true : false
                  }
                  media="(min-width: 2800px)"
                  {...(loaded[index] || nextLoaded[index] ? item : null)}
                />

                <Loader variant="simple" />
              </Inner>
            </Slide>
          ))}
        </Carousel>

        {images && images.length > 1 ? (
          <>
            <Media greaterThanOrEqual="ipadHorizontal">
              <Arrows row>
                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                  variant="invert"
                />
                <Arrow
                  direction="R"
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                  variant="invert"
                />
              </Arrows>
            </Media>

            <Media lessThan="ipadHorizontal">
              <Dots dial={5} row>
                {images.map((_item, index) => (
                  <Dot
                    key={index}
                    className={currentSlide === index ? 'active' : undefined}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(index)
                    }}
                  />
                ))}
              </Dots>
            </Media>
          </>
        ) : null}

        {images ? (
          <Modal className={modalGalleryStatus ? 'open' : undefined}>
            <ModalClose
              languageCode={languageCode}
              onClick={() => {
                setLocked(false)
                setModalGalleryStatus(false)
                setClickedSlide(currentSlide)
              }}
              variant="gallery"
            />
            <Gallery
              clickedSlide={clickedSlide}
              images={images}
              isOpen={modalGalleryStatus}
            />
          </Modal>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;
  &:hover {
    .zoom-icon {
      svg {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
    .slider {
      img {
        transform: scale(1.05);
      }
    }
  }

  .zoom-icon {
    svg {
      width: auto;
      height: 2.5rem;
      fill: none;
      opacity: 0;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      stroke-width: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.3);
      transition: 0.3s ease-in-out;
      z-index: 3;
    }
  }

  @media (max-width: 1023px) {
    &:hover {
      .slider {
        img {
          transform: none;
        }
      }
    }
  }
`

const Carousel = styled.div`
  display: flex;
  width: 100%;
  height: 67.3vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  outline: none;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.4)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    height: 40vh;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Loader = styled(Spinner)``

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  z-index: 2;
`

const Dots = styled(FlexBox)`
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
`

const Dot = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin: 0 0.625rem;
  position: relative;
  &.active {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 1;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
