import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Mq, Users } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect } from 'react'
import { scroller } from 'react-scroll'

import { Slider } from './Slider'

export interface Props {
  beButtonURL?: string
  description?: string
  images?: ImageProps[]
  languageCode: string
  pax?: string
  quotationPageURL?: string
  size?: string
  thumbnails?: ImageProps[]
  title?: string
}

export const Room = memo(function Room({
  beButtonURL,
  description,
  images,
  languageCode,
  pax,
  quotationPageURL,
  size,
  thumbnails,
  title,
}: Props) {
  if (!title) {
    return null
  }

  const section = title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParam = new URLSearchParams(window.location.search).get(
        'room',
      )

      if (searchParam) {
        scroller.scrollTo(`room-${searchParam}`, {
          offset: -60,
        })
      }
    }

    return () => {
      scroller.unmount()
    }
  }, [])

  return (
    <Container id={`room-${section}`} row stretch wrap>
      <LeftSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {pax || size ? (
          <FadeInUp>
            <Info dial={4} row wrap>
              {size ? (
                <Size dial={4} row>
                  <Mq />
                  {size}
                </Size>
              ) : null}
              {pax ? (
                <Pax dial={4} row>
                  <Users />
                  {pax}
                </Pax>
              ) : null}
            </Info>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {beButtonURL || quotationPageURL ? (
          <FadeInUp>
            <Buttons row wrap>
              {beButtonURL ? (
                <Button
                  label={useVocabularyData('book', languageCode)}
                  target="_blank"
                  URL={beButtonURL}
                />
              ) : null}
              {quotationPageURL ? (
                <Button
                  label={useVocabularyData('request', languageCode)}
                  URL={quotationPageURL}
                  variant="outline"
                />
              ) : null}
            </Buttons>
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide>
        {images ? (
          <Slider
            images={images}
            languageCode={languageCode}
            thumbnails={thumbnails}
          />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 12.5rem;
  &:nth-of-type(odd) {
    > div {
      &:first-of-type {
        order: 2;
        padding-right: 11.944vw;
        padding-left: 8.056vw;

        @media (max-width: 1199px) {
          padding-right: 2rem;
        }

        @media (max-width: 1023px) {
          padding-left: 2rem;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    margin-top: 6.875rem;
    &:nth-of-type(2) {
      margin-top: 0;
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 45%;
  padding: 0 8.056vw 0 11.944vw;

  @media (max-width: 1199px) {
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    order: 2;
    padding: 2.25rem 2rem 0;
    text-align: center;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 0.9375rem;
  margin-top: 1.375rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 1.375rem;
    fill: none;
    margin-right: 1rem;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
  }

  @media (max-width: 1023px) {
    justify-content: center;
    margin-top: 1rem;
  }
`

const Size = styled(FlexBox)`
  margin-right: 2.25rem;
`

const Pax = styled(FlexBox)``

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    margin-top: 2.3125rem;
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 2.5rem;
  a {
    margin-right: 0.375rem;
  }

  @media (max-width: 1023px) {
    justify-content: center;
  }
`

const RightSide = styled(FlexBox)`
  width: 55%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`
