import styled from '@emotion/styled'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Filters } from './Filters'
import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (!rooms) {
    return null
  }

  return (
    <Container>
      <Filters rooms={rooms} />

      {uniqBy(rooms, 'title').map((item, index) => (
        <Room key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
`
